/* Custom styles for SimpleMDE editor */

/* Editor mode styles */
.EasyMDEContainer .CodeMirror {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  font-size: 0.875rem; /* text-sm */
  line-height: 1.5;
  color: #2d3a8c; /* primary-800 */
}

.EasyMDEContainer .CodeMirror .cm-header-1 {
  font-size: 1.125rem; /* text-lg */
  line-height: 1.75rem;
  font-weight: 600;
  color: #2d3a8c; /* primary-800 */
}

.EasyMDEContainer .CodeMirror .cm-header-2 {
  font-size: 1rem; /* text-base */
  line-height: 1.5rem;
  font-weight: 600;
  color: #35469c; /* primary-700 */
}

.EasyMDEContainer .CodeMirror .cm-header-3 {
  font-size: 0.875rem; /* text-sm */
  line-height: 1.25rem;
  font-weight: 600;
  color: #4c63b6; /* primary-600 */
}

.EasyMDEContainer .CodeMirror .cm-header-4 {
  font-size: 0.875rem; /* text-sm */
  line-height: 1.25rem;
  font-weight: 500;
  color: #647acb; /* primary-400 */
}

.EasyMDEContainer .CodeMirror .cm-strong {
  color: #DE3A11; /* secondary-600 */
  font-weight: 600;
}

.EasyMDEContainer .CodeMirror .cm-code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  background-color: #f5f7fa; /* neutral-50 */
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #2d3a8c; /* primary-800 */
}

.EasyMDEContainer .CodeMirror .cm-quote {
  color: #52606d; /* neutral-600 */
  font-style: italic;
  border-left: 4px solid #e4e7eb; /* neutral-100 */
  padding-left: 1rem;
}

/* Preview mode styles */
.EasyMDEContainer .editor-preview,
.EasyMDEContainer .editor-preview-side {
  background-color: white;
}

.EasyMDEContainer .editor-preview h1,
.EasyMDEContainer .editor-preview-side h1 {
  font-size: 1.125rem; /* text-lg */
  line-height: 1.75rem;
  font-weight: 600;
  color: #2d3a8c; /* primary-800 */
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}

.EasyMDEContainer .editor-preview h2,
.EasyMDEContainer .editor-preview-side h2 {
  font-size: 1rem; /* text-base */
  line-height: 1.5rem;
  font-weight: 600;
  color: #35469c; /* primary-700 */
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.EasyMDEContainer .editor-preview h3,
.EasyMDEContainer .editor-preview-side h3 {
  font-size: 0.875rem; /* text-sm */
  line-height: 1.25rem;
  font-weight: 600;
  color: #4c63b6; /* primary-600 */
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.EasyMDEContainer .editor-preview h4,
.EasyMDEContainer .editor-preview-side h4 {
  font-size: 0.875rem; /* text-sm */
  line-height: 1.25rem;
  font-weight: 500;
  color: #647acb; /* primary-400 */
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.EasyMDEContainer .editor-preview strong,
.EasyMDEContainer .editor-preview-side strong {
  color: #DE3A11; /* secondary-600 */
  font-weight: 600;
}

.EasyMDEContainer .editor-preview code,
.EasyMDEContainer .editor-preview-side code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  background-color: #f5f7fa; /* neutral-50 */
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #2d3a8c; /* primary-800 */
}

.EasyMDEContainer .editor-preview pre,
.EasyMDEContainer .editor-preview-side pre {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem 0;
  overflow-x: auto;
}

.EasyMDEContainer .editor-preview pre code,
.EasyMDEContainer .editor-preview-side pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  font-size: 0.875rem;
  color: #2d3a8c; /* primary-800 */
  display: block;
  white-space: pre;
}

.EasyMDEContainer .editor-preview blockquote,
.EasyMDEContainer .editor-preview-side blockquote {
  border-left: 4px solid #e4e7eb; /* neutral-100 */
  padding-left: 1rem;
  margin: 1rem 0;
  color: #52606d; /* neutral-600 */
  font-style: italic;
}

.EasyMDEContainer .editor-preview hr,
.EasyMDEContainer .editor-preview-side hr {
  margin: 1.5rem 0;
  border-color: #e4e7eb; /* neutral-100 */
}

/* General editor container styles */
.EasyMDEContainer {
  font-size: 0.875rem; /* text-sm */
}

.EasyMDEContainer .editor-toolbar {
  border: 1px solid #e4e7eb; /* neutral-100 */
  background-color: #ffffff; /* neutral-50 */
}

.EasyMDEContainer .editor-toolbar button {
  color: #4c63b6 !important; /* neutral-500 */
  border-color: #e4e7eb; /* neutral-100 */
}

.EasyMDEContainer .editor-toolbar button:hover {
  border-color: #f35627; /* neutral-200 */
}

.EasyMDEContainer .editor-toolbar.disabled-for-preview button:not(.no-disable) {
  background: #f5f7fa; /* neutral-50 */
}
