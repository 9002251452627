.video-js {
  border-radius: 0.75rem !important;
  overflow: hidden !important;
}

/* Enhanced player container */
[data-vjs-player] {
  position: relative;
  z-index: 10;
  box-shadow: 
    0 0 100px 5px rgba(76, 99, 182, 0.4),
    0 0 50px 2px rgba(243, 86, 39, 0.3),
    inset 0 0 5px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

[data-vjs-player]:hover {
  box-shadow: 
    0 0 120px 10px rgba(76, 99, 182, 0.5),
    0 0 60px 5px rgba(243, 86, 39, 0.4),
    inset 0 0 5px rgba(255, 255, 255, 0.2);
}

/* Style the play button with brand colors */
.video-js .vjs-big-play-button {
  background-color: rgba(243, 86, 39, 0.85) !important;
  border: 2px solid rgba(243, 86, 39, 1) !important;
  border-radius: 9999px !important;
  box-shadow: 0 0 25px rgba(243, 86, 39, 0.6) !important;
  transition: all 0.3s ease;
}

.video-js:hover .vjs-big-play-button {
  background-color: rgba(243, 86, 39, 1) !important;
  transform: scale(1.1) !important;
  box-shadow: 0 0 35px rgba(243, 86, 39, 0.8) !important;
}

.pulse-animation {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(242, 105, 71, 0.7);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(242, 105, 71, 0.7);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(242, 105, 71, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(242, 105, 71, 0);
  }
}