@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500;
  }
  .btn {
    @apply inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500;
  }
  .CodeMirror-line,
  .CodeMirror-linenumber {
    @apply text-primary-600;
  }
}

.CodeMirror {
  border: none !important;
}

.editor-toolbar {
  border: none !important;
}

.CodeMirror-line,
.CodeMirror-linenumber {
  color: #4c63b6 !important;
}

@supports (-webkit-touch-callout: none) {
  .h-full {
    height: -webkit-fill-available;
  }
}
